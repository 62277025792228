import {apiWithNoAuth, apiWithToken} from '@/services/api';

const actions = {

  slack({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithNoAuth()
        .post('messaging/slack/pharmacy', {message: payload.message})
        .then((response) => {
          resolve(response.data)
        })
        .catch(err => {reject(err)})
    })
  }
};

export default {
  namespaced: true,
  actions,
};
