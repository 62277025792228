import {createApp} from 'vue'
import App from './App'
import router from './router/index.js'
import store from './store'
import {auth} from './firebase'
let Bootstrap = require('bootstrap')
import 'bootstrap/dist/css/bootstrap.css'
require('./assets/plugins/fontawesome/css/fontawesome.min.css')
require('./assets/plugins/fontawesome/css/all.min.css')
import InstantSearch from 'vue-instantsearch/vue3/es'

if(window.location.pathname.includes("/admin")) {
  require('./assets/css/generalstyle.css');
  require('./assets/css/adminstyle.css');
} else {
  require('./assets/css/generalstyle.css');
  require('./assets/css/style.css');
}
let app
auth.onAuthStateChanged( (user) => {
  if (!app) {
    app = createApp(App)
    app.use(router);
    app.use(InstantSearch);
    app.use(store);

    router.isReady().then(() => {
      app.mount('#app');
    });
  }
    if (user) {
      store.dispatch("auth/signIn", user);
    } else {
      store.dispatch('getRedirectResult').then(u => {
        if (u) {
          store.dispatch('auth/signIn', u)
        }
      }).catch(e => {
            store.dispatch('auth/signOut').catch()
            this.$router.push({
              path: '/login', query: {
                status: e.status
              }
            })
          }
      )

  }
});






