import actions from "./actions"
import auth from "@/store/modules/auth"
import { createStore } from "vuex"
import easydoc from "@/store/modules/easydoc"
import engage from "@engage_so/js"
import events from "@/store/modules/events"
import providers from "@/store/modules/providers"
import rest from "@/store/modules/rest"
import twilio from "@/store/modules/twilio"

engage.init({
  key: '42b2c6f1a99137177a6446551e77e25a',
  secret: '316b523f9cc9b359ed0392a5da789d53'
})

export default createStore({
  state: {
    profile : {
      userProfile : {},
      provider : null,
      patient : null,
      type: ''
    }

  },
  mutations: {
    setUserProfile(state, data){
      console.log("setting user profile")
      console.log(data)
      if (data) {
        state.profile.userProfile = data

      }
    },
    setPatientProfile(state, data,){
      if (data) {
        state.profile.patient = data.data()
        state.profile.type = 'patient'

      }
    },
    setProviderProfile(state, data,){
      if (data) {
        state.profile.provider = data.data()
        state.profile.type = 'provider'
      }
    },

    clearProfiles(){
      this.state.userProfile = {}
      this.state.providerProfile = null
      this.state.patient = null

    }
  },
  actions: {...actions},
  modules: {
    auth : auth,
    easydoc : easydoc,
    events: events,
    providers: providers,
    rest : rest,
    twilio : twilio,
  }
})
