export default [
  {
    path: '', alias: 'index',
    name: 'admin-dashboard',
    component: () => import('../components/admin/index'),
  },
  {
    path: 'easydoc/settings',
    name: 'easy-settings',
    component: () => import('../components/admin/easydoc/settings.vue'),
  },
  {
    path: 'easydoc/events',
    name: 'easy-events',
    component: () => import('../components/admin/easydoc/events/events.vue'),
  },
  {
    path: 'easydoc/events/create-event',
    name: 'easy-create-event',
    component: () => import('../components/admin/easydoc/events/event.vue'),
  },
  {
    path: 'easydoc/event/:eventId',
    name: 'easy-event',
    component: () => import('../components/admin/easydoc/events/event.vue'),
    props: true,
  },
  {
    path: 'easydoc/providers',
    name: 'easy-providers',
    component: () => import('../components/admin/easydoc/providers/providers.vue'),
  },
  {
    path: 'easydoc/events/create-provider',
    name: 'easy-create-provider',
    component: () => import('../components/admin/easydoc/providers/Provider.vue'),
  },
  {
    path: 'easydoc/provider/:providerId',
    name: 'easy-provider',
    component: () => import('../components/admin/easydoc/providers/Provider.vue'),
    props: true,
  },
  {
    path: 'easydoc/provider/:providerId/manage-provider',
    name: 'easy-provider-services',
    component: () => import('../components/admin/easydoc/providers/ManageProvider.vue'),
    props: true,
  },
  {
    path: 'easydoc/promotions',
    name: 'easy-promotions',
    component: () => import('../components/admin/easydoc/promotions.vue'),
  },
  {
    path: 'easydoc/promotions/create',
    name: 'easy-create-promotions',
    component: () => import('../components/admin/easydoc/createpromotion.vue'),
  },
  {
    path: 'easydoc/messages',
    name: 'easy-settings',
    component: () => import('../components/admin/easydoc/Chat.vue'),
  },
  {
    path: 'easydoc/businesses',
    name: 'easy-business',
    component: () => import('../components/admin/easydoc/businesses'),
  },
  {
    path: 'easydoc/textqueue',
    name: 'easy-queue',
    component: () => import('../components/admin/easydoc/TextSessionQueue.vue'),
  },
  {
    path: 'easydoc/text/:id/view', alias: 'easydoc/text/:id/start',
    name: 'easy-queue-view',
    component: () => import('../components/admin/easydoc/TextSession.vue'),
    props: true
  },
  {
    path: 'easydoc/business/:id',
    name: 'easy-business-view',
    props: true,
    component: () => import('../components/admin/easydoc/business'),
  },
  {
    path: 'easydoc/:id/log',
    name: 'easy-business-log',
    props: true,
    component: () => import('../components/admin/easydoc/BusinessLog.vue'),
  },
  {
    path: 'easydoc/business/:id/manage-subscription',
    name: 'easy-subscription-view',
    props: true,
    component: () => import('../components/admin/easydoc/ManageSubscription.vue'),
  },
  {
    path: 'easydoc/:id/team',
    name: 'easy-team-view',
    props: true,
    component: () => import('../components/admin/easydoc/teammembers.vue'),
  },
  {
    path: 'easydoc/:id/admin',
    name: 'easy-team-admin-view',
    props: true,
    component: () => import('../components/admin/easydoc/teamadmin.vue'),
  },
  {
    path: 'easydoc/:businessId/team/:memberId',
    name: 'easy-team-member-view',
    props: true,
    component: () => import('../components/admin/easydoc/teammember.vue'),
  },
  {
    path: 'easydoc/consults',
    name: 'easy-consults',
    component: () => import('../components/admin/easydoc/consults.vue'),
  },
  {
    path: 'easydoc/consult/:id/documents',
    name: 'easydoc-consult-script',
    component: () => import('../components/admin/easydoc/addscript.vue'),
    props: true
  },
  {
    path: 'easydoc/consult/:id/view',
    name: 'easydoc-view-consult',
    component: () => import('../components/admin/easydoc/viewconsult.vue'),
    props: true
  },
  {
    path: 'easydoc/consult/:id/:action',
    name: 'easydoc-schedule-consult',
    component: () => import('../components/admin/easydoc/consult.vue'),
    props: true
  },
  {
    path: 'easydoc',
    name: 'easy-dashboard',
    component: () => import('../components/admin/easydoc/index'),

  },
  {
    path: 'appointmentlist',
    name: 'appointmentlist',
    component: () => import('../components/admin/appointment/appointmentlist'),
  },
  {
    path: 'appointments/viewbooking/:bookingCode',
    name: 'admin-view-booking',
    component: () => import('../components/admin/appointment/viewbooking'),
    props: true,
  },
  {
    path: 'renewals',
    name: 'renewalrequestlist',
    component: () => import('../components/admin/scripts/scriptrenewals'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'reviews',
    name: 'view-reviews',
    component: () => import('../components/admin/reviews'),
  },
  {
    path: 'view-review',
    name: 'view-review',
    component: () => import('../components/admin/reviews/viewreview'),
  },
  {
    path: 'review/:id',
    name: 'internal-review-details',
    component: () => import('../components/admin/reviews/clientreviewdetails'),
  },
  {
    path: 'provider-review/:id',
    name: 'provider-review-details',
    component: () => import('../components/admin/reviews/providerreviewdetails'),
  },
  {
    path: 'pharmacy-scripts',
    name: 'pharmacy-scripts',
    component: () => import('../components/admin/scripts/pharmacyscripts'),
  },
  {
    path: 'contraceptive-scripts',
    name: 'contraceptive-scripts',
    component: () => import('../components/admin/scripts/contraceptivescripts'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'scripts/:sid/delete',
    name: 'delete-script',
    props: true,
    component: () => import('../components/admin/scripts/deletescript')
  }, {
    path: 'scripts/:sid/re-subscribe',
    name: 'resubscribe-script',
    props: true,
    component: () => import('../components/admin/scripts/resubscribescript'),
  }, {
    path: 'scripts/:sid/cancel',
    name: 'cancel-subscription',
    props: true,
    component: () => import('../components/admin/scripts/cancelsubscription'),
  },
  {
    path: 'promotions',
    name: 'promotions',
    component: () => import('../components/admin/promotions'),
  },
  {
    path: 'promotions/create',
    name: 'create-promotion',
    component: () => import('../components/admin/createpromotion'),
  },
  {
    path: 'promotions/edit/:id',
    name: 'edit-promotion',
    props: true,
    component: () => import('../components/admin/createpromotion'),
  },
  {
    path: 'appointments',
    name: 'appointments',
    component: () => import('../components/admin/appointment/appointmentlist')
  },
  {
    path: 'add-script',
    name: 'add-script',
    component: () => import('../components/admin/scripts/addscript')
  },
  {
    path: 'add-path-result/:resultId',
    props: true,
    name: 'update-lab-result',
    component: () => import('../components/admin/pathology/addlabresult')
  },
  {
    path: 'add-path-result',
    name: 'add-lab-result',
    component: () => import('../components/admin/pathology/addlabresult')
  },
  {
    path: 'renew-script/:scriptId',
    name: 'renew-script',
    component: () => import('../components/admin/scripts/addscript'),
    props: true
  },
  {
    path: 'add-script/:bookingCode',
    name: 'add-consult-script',
    component: () => import('../components/admin/scripts/addscript'),
    props: true
  },
  {
    path: 'scripts/:scriptId/view',
    name: 'view-script',
    component: () => import('../components/admin/scripts/reviewscript'),
    props: true
  },
  {
    path: 'pharmacy-scripts/:scriptId/view',
    name: 'view-pharmacy-script',
    component: () => import('../components/admin/scripts/reviewpharmacyscript'),
    props: true
  },
  {
    path: 'providerlist',
    name: 'providerlist',
    component: () => import('../components/admin/doctorlist'),
  },
  {
    path: 'patientlist',
    name: 'patientlist',
    component: () => import('../components/admin/patientlist'),
  },
  {
    path: 'path-results',
    name: 'path-results',
    component: () => import('../components/admin/pathology/labresults'),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import('../components/admin/settings'),
  },
  {
    path: 'provider-profile/:providerId/approve',
    name: 'admin-provider-approval',
    component: () => import('../components/admin/approveprovider'),
    props: true,

  },
  {
    path: 'provider-profile/:userId',
    name: 'admin-provider',
    component: () => import('../components/admin/providerprofile'),
    props: true,
  }
]
