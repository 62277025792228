// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/auth';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAeTZXJ8Hg-qt1XGboWRhxoQY70sZxCyfg",
  authDomain: "zoie-health.firebaseapp.com",
  databaseURL: "https://zoie-health-default-rtdb.firebaseio.com",
  projectId: "zoie-health",
  storageBucket: "zoie-health.appspot.com",
  messagingSenderId: "835851317863",
  appId: "1:835851317863:web:ddd1ec01734b99be0c1ac2",
  measurementId: "G-500VXJRDEQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage().ref();


const db = firebase.firestore();
const auth = firebase.auth();
const google = new firebase.auth.GoogleAuthProvider()

const providerCollection = db.collection('providers');
const partnershipCollection = db.collection('partnerships');
const userCollection = db.collection('users');
const patientCollection = db.collection('patients');
const scriptsCollection = db.collection('scripts');
const scriptRenewalCollection = db.collection('script-renewals');
const mailCollection = db.collection('mail');
const promotionsCollections = db.collection('promotions')
const clicksCollections = db.collection('promotional-clicks')
const bookingLogs = db.collection('booking-logs')
const pathologyResults = db.collection('pathology-results')

const medicalAidCollection  = db.collection('medical-aid')
const reviews = db.collection('reviews')
const providerReviews = db.collection('provider-reviews')
const fertilityInterviews = db.collection('fertility-interview')


export {db, auth, storage, google, mailCollection, providerCollection, partnershipCollection, userCollection, patientCollection, scriptsCollection, scriptRenewalCollection, promotionsCollections, clicksCollections, bookingLogs, pathologyResults, medicalAidCollection, providerReviews, reviews, fertilityInterviews}
