import {createRouter, createWebHistory} from 'vue-router'
import {redirectIfNecessary, requireAdminAuth, requireAuth} from "@/guards/authGuard";

import adminRoutes from './admin.js'
import patientRoutes from './patient.js'
import providerRoutes from './provider.js'
import pharmacyRoutes from "./pharmacy";
import {auth} from "@/firebase"
import store from '../store'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/', alias: '/index',
      name: 'general',
      component: () => import('../components/layouts/patient/PatientLayout.vue'),
      children:[
        {
          path: '/', redirect: '/patient/category-consult',
          name: 'home',
          component: () => import('../components/Index'),
        },
        {
          path: '/provider/profile/:providerId',
          name: 'provider-profile',
          component: () => import('../components/doctor/Profile'),
          props: true
        },
        {
          path: '/mycontraceptive',
          name: 'contraceptive-consult',
          component: () => import('../components/patient/MakeABooking')
        }

      ],
    },
    {
      path: '/login',
      name: 'logins',
      component: () => import('../components/Login'),
      beforeEnter: redirectIfNecessary
    },

    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('../components/ForgotPassword')
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import(/* webpackChunkName: "admin" */'../components/layouts/admin/AdminLayout.vue'),
      children: [...adminRoutes],
      beforeEnter: requireAdminAuth
    },
    {
      path: '/patient',
      name: 'Patient',
      component: () => import(/* webpackChunkName: "patients" */'../components/layouts/patient/PatientLayout.vue'),
      children:[...patientRoutes],
    },
    {
      path: '/pharmacy',
      name: 'Pharmacy',
      component: () => import(/* webpackChunkName: "patients" */'../components/layouts/patient/PatientLayout.vue'),
      children:[...pharmacyRoutes],
    },
    {
      path: '/provider',
      name: 'Provider',
      component: () => import('../components/layouts/doctors/ProviderLayout'),
      children:[...providerRoutes],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/patient/register',
      name: 'patient-registration',
      component: () => import( '../components/patient/Register')
    },
    {
      path: '/provider/register',
      name: 'provider-registration',
      component: () => import( '../components/doctor/Register')
    },
    {
      path:'/zuper/:partnerId',
      name: 'partner-action-i-link',
      component: () => import('../components/Index'),
      props: true,
      beforeEnter(to, _from, next){
        store.dispatch('trackClick', {
          identifier: to.params.partnerId,
          type: 'INTERNAL',
          referrer: document.referrer,
          date: new Date()
        }).then(() => next('/gotomain'))

      }
    },{
      path:'/partner/:partnerId',
      name: 'partner-action-link',
      component: () => import('../components/Index'),
      props: true,
      beforeEnter(to, _from, next){
        store.dispatch('trackClick', {
          identifier: to.params.partnerId,
          referrer: document.referrer,
          type: 'PARNTER',
          date: new Date()
        }).then(() =>next('/gotomain'))
      }
    },
    {
      path: '/gotomain',
      component: () => import('../components/Index'),
      beforeEnter(_to, _from, _next) {
        window.location.href = 'https://www.zoiehealth.com';
      }
    },
    {
      path: '/admin/login',
      name: 'login',
      component: () => import('../components/admin/login'),
    },
    {
      path: '/lockscreen', alias : '/admin/lockscreen',
      name: 'lockscreen',
      component: () => import('../components/admin/lockscreen')
    },
    {
      path: '/error500', alias : '/admin/error500',
      name: 'error500',
      component: () => import('../components/admin/error500')
    },
   {
      path: '/403', alias : '/admin/403',
      name: '403Error',
      component: () => import('../components/admin/error403')
    },{
      path:'/404',
      name: '404Error',
      component:() => import('../components/admin/error404')
    }
  ],
  linkActiveClass: "active",
  scrollBehavior (_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
