export default [
  {
    path: 'change-password',
    name: 'provider-forgot-password',
    component: () => import('../components/doctor/ChangePassword'),
  },
  {
    path: 'profile-settings',
    name: 'profile-settings',
    component: () => import('../components/doctor/ProfileSettings'),

  },
  {
    path: 'dashboard',
    name: 'provider-dashboard',
    component: () => import('../components/doctor/Index'),
  },
  {
    path: 'qualifications',
    name: 'provider-qualifications',
    component: () => import('../components/doctor/Qualifications'),
  },
  {
    path: 'agreements',
    name: 'provider-agreements',
    component: () => import('../components/doctor/Legal'),
  },
  {
    path: 'services',
    name: 'provider-services',
    component: () => import('../components/doctor/Services'),
  },
]
