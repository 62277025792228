const loginClient = new JSONRpcClient({
  'url': 'https://user-api.simplybook.me' + '/login',
  'onerror': function (error) {},
});
const token = loginClient.getToken( 'zoiehealth', '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662');


const api = new JSONRpcClient({
  'url': 'https://user-api.simplybook.me',
  'headers': {
    'X-Company-Login': 'zoiehealth',
    'X-Token': token
  },
  'onerror': function (error) { console.log(error)}
});

let adminClient = new JSONRpcClient({
  'url': 'https://user-api.simplybook.me' + '/login',
  'onerror': function (error) {},
});
let adminToken = adminClient.getUserToken( 'zoiehealth',  'webapp',  'ServingW0men3xcepti0na1Ly');


const admin = new JSONRpcClient({
  'url': 'https://user-api.simplybook.me' + '/admin/',
  'headers': {
    'X-Company-Login': 'zoiehealth',
    'X-User-Token': adminToken
  },
  'onerror': function (error) {}
});


export {api, admin}
