import { apiWithToken } from "@/services/api"

const actions = {
  activateProvider({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post(`admin/provider/${payload.data.providerId}/activate`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  createProvider({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post('admin/provider', JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  deactivateProvider({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post(`admin/provider/${payload.data.providerId}/deactivate`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getProvider({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get(`admin/provider/${payload.data.providerId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getProviders({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get('admin/providers')
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getProvidersByType({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get(`admin/providers/type/${payload.data.providerType}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  updateProvider({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .put(`admin/provider/${payload.data.providerId}`, JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  // services
  addService({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post(`admin/provider/${payload.data.providerId}/service/${payload.data.serviceId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getServices({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get('admin/services')
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  removeService({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .delete(`admin/provider/${payload.data.providerId}/service/${payload.data.serviceId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },

}

export default {
    namespaced: true,
    actions,
}
