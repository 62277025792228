import * as firestore from '@/firebase'

const state = {
  user : null,
  redirectPath: null,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setRedirectPath(state, path) {
    state.redirectPath = path;
  },
};

const actions = {
  login({dispatch}, form) {
    return new Promise((resolve, reject) => {
      firestore.auth.signInWithEmailAndPassword(form.email, form.password)
          .then((user) => {
            dispatch('setUser', user)
            resolve(user)
          })
          .catch(err => {reject(err)})
    })
  },
  signIn({ commit }, user) {
    commit("setUser", user);
  },
  redirect({ commit, state }, router) {
    if (state.redirectPath) {
      router.push(state.redirectPath);
      commit("setRedirectPath", null);
    } else {
      router.push("/dashboard"); // Default redirect if no path is saved
    }
  },
  signOut({ commit }) {
    firestore.auth.signOut();
    commit("setUser", null);
  },
};

const getters = {
  isAuthenticated: (state) => {
    return !!state.user
  },
  isAdmin: (state) => {
    return state.user && state.user.email.endsWith('@zoiehealth.com')
  },
  getAuthToken: (state) => {
    let token = state.user.email+':'+state.user.uid
    return btoa(token)
  },
  getUser: (state) => state.user,
  getRedirectPath: (state) => state.redirectPath,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
