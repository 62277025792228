import { apiWithToken } from '@/services/api'

const actions = {
    activateBusiness({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).put(`business/${payload.id}/activate` , JSON.stringify(payload.data))
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err.response.data)})
        })
    },
    getBusinesses({dispatch}, token){
        return new Promise((resolve, reject) => {
            apiWithToken(token).get('admin/businesses')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
    getConsults({dispatch}, token){
        return new Promise((resolve, reject) => {
            apiWithToken(token).get('admin/business/consults')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
  getProviders({dispatch}, token) {
    return new Promise((resolve, reject) => {
      apiWithToken(token).get('admin/providers')
        .then((response) => {
          resolve(response.data)
        })
        .catch(err => { reject(err) })
    })
  },
  getConsult({dispatch}, payload){
      console.log(payload)
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('admin/business/consult/'+payload.id)
                .then((response) => {
                  if(response.status === 200){
                    resolve(response.data)
                  } else{
                    reject() }
                  })
                .catch(err => {reject(err)})
        })
    },
    updateConsult({dispatch}, payload){ //consultId, token
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token)
              .post('admin/business/'+payload.businessId +'/consult/'+payload.consultId+'/record', payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
         })},
    getBusiness({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('business/'+payload.id)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
  getBusinessLogo({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('business/logo/'+payload.id)
                .then((response) => {
                  console.log(response)
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
  getTextSession({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('admin/business/text/'+payload.id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })

  },
    updateBusiness({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).put('business/'+payload.id, JSON.stringify(payload.data))
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err.response.data)})
        })
    },
    updateSubscription({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).put(`business/${payload.id}/subscription`, JSON.stringify(payload.data))
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err.response.data)})
        })
    },
    getTeam({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('business/'+payload.id+'/team')
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
  getQueue({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('admin/business/queue')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
    getTeamAdmin({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('admin/business/'+payload.id+'/admin')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
  inviteSMS({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('business/'+payload.id+'/team/'+ payload.memberId+'/invite')
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
  inviteSMSes({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('business/'+payload.id+'/team/invite')
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    inviteAdmin({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token)
              .post('business/'+payload.id+'/member-admin', JSON.stringify(payload.data))
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    inviteTeamMember({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token)
              .post('business/'+payload.id+'/member', JSON.stringify(payload.data))
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    getTeamMember({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).get('business/'+payload.id+'/team/'+payload.memberId)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    archiveBusiness({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).delete(`business/${payload.id}`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    cancelBusiness({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post(`business/${payload.id}/deactivate`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    },
    getBusinessCount({dispatch}, token){
        return new Promise((resolve, reject) => {
            apiWithToken(token).get('admin/business/count')
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {reject(err)})
        })
    }
    ,
    getBusinessTeamCount({dispatch}, token){
        return new Promise((resolve, reject) => {
            apiWithToken(token).get('admin/business/members/count')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
  bookConsult({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('admin/business/'+payload.id+'/consult', payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
  },
  cancelConsult({dispatch}, payload){
        return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('admin/business/consult/'+payload.id+'/cancel')
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
        })
    },
  rescheduleConsult({dispatch}, payload){
      return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('admin/business/consult/'+payload.id+'/reschedule', payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
      })
  },
  confirmConsult({dispatch}, payload){
      return new Promise((resolve, reject) => {
            apiWithToken(payload.token).post('admin/business/consult/'+payload.id+'/confirm', payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {reject(err)})
      })
  },
  getUser({dispatch}, payload){
    return new Promise((resolve, reject) => {
        apiWithToken(payload.token)
        .get('UserAuth/' + payload.userAuthId)
        .then((response) => {
            resolve(response.data)
        })
        .catch(err => { reject(err) })
    })
  },
  inviteAdminById({dispatch}, payload){
    return new Promise((resolve, reject) => {
        apiWithToken(payload.token)
        .post('business/' + payload.businessId + "/admin/" + payload.userAuthId)
        .then((response) => {
            resolve(response.data.value)
        })
        .catch(err => { reject(err) })
    })
  },
    async getNotifications({ commit }, payload) {
        return apiWithToken(payload.token)
        .get(`admin/business/${payload.businessId}/transactionLogs`)
        .then(response => {
            return response.data.value;
        })
        .catch(err => {
            commit('eventVisibleErr', err)
        })
    },
}

export default {
    namespaced: true,
    actions,
};
