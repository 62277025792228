import {apiTwilio, apiWithToken} from "@/services/api";
const actions = {
  getMessages({ dispatch }) { // eslint-disable-line no-unused-vars
    return new Promise((resolve, reject) => {
      apiTwilio()
        .get('Messages.json?PageSize=500')
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMessagesByQuery({ dispatch }, query) { // eslint-disable-line no-unused-vars
    console.log(query)
    return new Promise((resolve, reject) => {
      apiTwilio()
        .get('Messages.json?PageSize=100&'+query)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendMessage({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      apiWithToken(payload.authToken)
        .post('messaging/sendWhatsappMessageToUser', null, {params: payload.data})
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
export default {
  namespaced: true,
  actions,
};
