// api.js
import axios from 'axios';


/**
 * auth token is a base64 encoded string of the username and sid
 * **/
export function apiWithToken(authToken){
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic ' + authToken,
            'Content-Type': 'application/json'
    }});
}
export function apiWithNoAuth(){
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic dXNlckB6b2llaGVhbHRoLmNvbTpCb29raW5ncw==',
            'Content-Type': 'application/json'
    }});
}

export function apiTwilio(){
  return axios.create({
    baseURL: 'https://api.twilio.com/2010-04-01/Accounts/ACd9185d7fb62a9d717b624816f6983c33/',
    headers:{
      'Content-Type': 'application/json',
      'Authorization' : 'Basic ' + 'QUNkOTE4NWQ3ZmI2MmE5ZDcxN2I2MjQ4MTZmNjk4M2MzMzpmOGUyMzQ4YTk3MmEzMzNiODBmMjI2YjdhN2IzNTg0Nw=='
    }
  });
}


