import { apiWithToken } from "@/services/api"

const actions = {
  cancelEvent({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .delete(`admin/business/event/${payload.data.eventId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  createEvent({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post('admin/business/event', JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getEvent({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get(`admin/business/event/${payload.data.eventId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getEvents({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get('admin/business/events')
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  getEventProviders({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .get(`admin/business/event/${payload.data.eventId}/providers`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  notifyMembers({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post(`admin/business/event/${payload.data.id}/notify`, JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  rescheduleEvent({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .post(`admin/business/event/${payload.data.id}/reschedule`, JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
  updateEvent({dispatch}, payload){
    return new Promise((resolve, reject) => {
      apiWithToken(payload.token)
      .put(`admin/business/event/${payload.data.id}`, JSON.stringify(payload.data))
      .then((response) => {
        resolve(response.data)
      })
      .catch(err => {reject(err)})
    })
  },
}

export default {
    namespaced: true,
    actions,
}
