import store from "@/store";

export function requireAuth(to, from, next) {
    console.log(store.getters["auth/getUser"])
    if (!store.getters["auth/isAuthenticated"]) {
        store.commit("auth/setRedirectPath", to.fullPath);
        next("/login");
    } else {
        next();
    }
}
export function redirectIfNecessary(to, from, next) {
    if (store.getters["auth/isAuthenticated"]) {
        if(to.path === '/login') {
            next('/')
        } else if (to.path === '/admin/login') {
            next('/admin')
        }
    } else {
        next();
    }
}

export function requireAdminAuth(to, from, next) {
    if (store.getters["auth/isAdmin"]) {
        next();
    } else if(store.getters["auth/isAuthenticated"]){
        store.dispatch('logoutOnly').catch()
        store.dispatch('auth/signOut').catch()
        next('/admin/403');
    } else {
        store.commit("auth/setRedirectPath", to.fullPath);
        next("/admin/login");
    }
}
